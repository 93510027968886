/**
 * @author gudiskis PrivaSphere confidential, (c) 2025 - 2025 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/165
 *
 */
import { LoggerFactory } from "myd-commons";
import { DATA_PROGRESS } from "../BrowserUpload/uiUpdates";
import { bytesToBestFit } from "../BrowserUpload/utilsFile";
import { CustomUploadBtn } from "../CustomUploadBtn";
import { Utils } from "../Utils";
import { RequestUtils } from "../RequestUtils";
import constVar from "../../../angular/src/assets/const.json";
export class XMLHandlerImplementation {
    constructor() {
        this.logger = LoggerFactory.getLogger(XMLHandlerImplementation.name);
    }
    onloadLarge(event, barDiv, progress) {
        if (null != barDiv) {
            barDiv.removeChild(progress);
        }
    }
    onabortLarge(event, barDiv, progress, file, reject) {
        this.logger.log(`onabort ${file.name}, event ${event}, type ${event.type}`);
        if (barDiv) {
            barDiv.removeChild(progress);
        }
        reject(new Error("upload was aborted"));
    }
    setRequestHeaderFromForm(xhr) {
        let fFrom = document.getElementById(constVar["sd_fFrom"]);
        if (fFrom) {
            xhr.setRequestHeader(constVar["gc_NON_PAY_SENDR"], fFrom.value);
        }
        return xhr;
    }
    onload(event, xhr, reject, file, inputID, resolve) {
        this.logger.log(`onload ${xhr.status} "${file.name}" ${xhr.getAllResponseHeaders()}`);
        if (xhr.status !== 200 && xhr.status !== 204) {
            this.logger.warn(`status is not 200 or 204 ${xhr.status} "${file.name}"`);
            reject(new Error(xhr.response));
            return xhr.onerror(event);
        }
        resolve(true);
        return true;
    }
    onProgressLarge(inputID, fileIndex, event, barDiv, bar, xhr, file) {
        const progressDiv = document.getElementById(`${DATA_PROGRESS}${inputID}_` + fileIndex);
        let done = event.loaded;
        let total = event.total;
        let uploadProgress = (done / total) * 100;
        if (null != barDiv && null != progressDiv) {
            progressDiv.innerHTML = bytesToBestFit(done) + " / " + bytesToBestFit(total);
            bar.style.width = uploadProgress + "%";
            this.logger.log(`upload.onprogress ${xhr.status} "${file.name}", ${uploadProgress}%`);
        }
    }
    onerror(event, inputID, file, reject, resolve) {
        this.logger.error(`onerror: ${file.name}, event ${event}, type ${event.type}`);
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            this.onreadystatechange(xhr, inputID, resolve, reject);
        };
        xhr.open('GET', `/${constVar["gc_GET_ERR_DIV"]}` + RequestUtils.completeReqURL("?"), true);
        xhr.send(null);
        reject(new Error(`Network error during upload: ${file.name}`));
        return false;
    }
    onreadystatechange(xhr, inputID, resolve, reject) {
        var _a;
        if (xhr.readyState == 4) {
            if (xhr.status == 200) {
                this.logger.log('successful upload for ' + inputID);
                resolve(true);
            }
            var errDiv = this.readBody(xhr);
            if (null != errDiv && "" != errDiv) {
                CustomUploadBtn.setUploadFieldToErr(inputID);
                var errWrp = document.getElementById('eRrWrP');
                if (null != errWrp) {
                    errWrp.innerHTML = errDiv;
                }
                document.body.style.cursor = 'default';
                let contaFrmSnd = Utils.getContaFrmSnd();
                this.enableButton(contaFrmSnd);
                let inputField = document.getElementById(inputID);
                this.logger.error(`onerror: '${inputField.name}', value: '${inputField.value}', files.length: '${(_a = inputField.files) === null || _a === void 0 ? void 0 : _a.length}`);
                return false;
            }
        }
        else {
            this.logger.warn('error request state: ' + xhr.readyState);
            reject(new Error('error request state: ' + xhr.readyState));
        }
        return true;
    }
    readBody(xhr) {
        if (!xhr.responseType || xhr.responseType === "text") {
            return xhr.responseText;
        }
        else if (xhr.responseType === "document") {
            return xhr.responseXML ? new XMLSerializer().serializeToString(xhr.responseXML) : "";
        }
        else {
            return xhr.response;
        }
    }
    enableButton(button) {
        if (button === null) {
            this.logger.trace('null == button');
            return;
        }
        const btnName = button.value;
        this.logger.log(`enableButton "${btnName}"`);
        button.disabled = false;
        // Handle potential typo
        button.disabl = false; // Remove or fix this line if it's unintended
        if (button.style) {
            button.style.cursor = button.style.origCursor || '';
            button.style.pointerEvents = '';
            if (button.style.origBackground) {
                button.style.background = button.style.origBackground;
            }
            if (button.style.origColor) {
                button.style.color = button.style.origColor;
            }
            if (button.style.origHeight) {
                button.style.height = button.style.origHeight;
            }
            if (button.style.origFontWeight) {
                button.style.fontWeight = button.style.origFontWeight;
            }
            if (button.style.origFontStyle) {
                button.style.fontStyle = button.style.origFontStyle;
            }
            if (button.style.origFontSize) {
                button.style.fontSize = button.style.origFontSize;
            }
        }
        if (button.origValue) {
            button.value = button.origValue;
        }
    }
}
