/**
 * @author gudiskis, ancona PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/640
 *
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import constVar from "../../angular/src/assets/const.json";
export class RequestUtils {
    static completeReqURL(delim) {
        let now = constVar["gc_NOW_SHORT"];
        let jsessionID = constVar["gc_JSESSIONID"];
        let login = constVar["gc_LOGIN_KEY"];
        let dotD = ".d;";
        let urlParams = [];
        if (this.getParam(jsessionID)) {
            let jsessionIdString = `${jsessionID}=` + this.getParam(jsessionID);
            urlParams.push(jsessionIdString);
        }
        if (this.getParam(login)) {
            let loginString = `${login}=` + this.getParam(login);
            urlParams.push(loginString);
        }
        if (this.getParam(now)) {
            let n0wString = `${now}=` + this.getParam(now);
            urlParams.push(n0wString);
        }
        let res = '';
        for (let i = 0; i < urlParams.length; i++) {
            if (i === 0) {
                res += dotD + urlParams[i];
            }
            else if (i === 1) {
                res += "?" + urlParams[i];
            }
            else {
                res += "&" + urlParams[i];
            }
        }
        return res;
    }
    static getJSessonID() {
        return this.getParam(constVar["gc_JSESSIONID"]);
    }
    static getParam(name) {
        return this.getParamFromString(name, window.location.href);
    }
    static getParamFromString(name, url) {
        let rx = new RegExp("[;?&]" + name + "=([^&?/]+).*$");
        let returnVal = url.match(rx);
        return returnVal === null ? "" : returnVal[1];
    }
    static makeRequest(url, method, data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const xhr = new XMLHttpRequest();
                xhr.open(method, url, true);
                xhr.setRequestHeader("Content-Type", "application/json");
                const response = yield new Promise((resolve, reject) => {
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                resolve(xhr.responseText);
                            }
                            else {
                                reject(xhr.responseText);
                            }
                        }
                    };
                    xhr.send(JSON.stringify(data));
                });
                return response;
            }
            catch (error) {
                throw new Error(error);
            }
        });
    }
}
