/**
 * @author antonio, gudiskis PrivaSphere confidential, (c) 2024 - 2025 all rights reserved
 *
 *
 * XmlHTTPUpload is a class that handles the upload of files to the backend using XMLHTTP Requests
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/138
 *
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getExistingFiles, getSizeOfAllFiles } from "../BrowserUpload/AutoUpload2";
import { LoggerFactory } from "myd-commons";
import constVar from "../../../angular/src/assets/const.json";
/*
addFile -> Called when you "Browse"
    It decides if the field "uploadIfNecessary" of that file should be defined
    that field contains the function that defines the XMLHTTP Requestt

uploadFiles -> Called when "Submit " is clicked
    it re-checks every file, and if a file is too big (fFile.pendingUploads exists),
     file.uploadIfNecessary
    is called, executing the previously defined XMLHTTP Request
*/
export class XMLHTTPUpload {
    constructor(XMLHandler, xmlHttpHelpers) {
        this.XMLHandler = XMLHandler;
        this.xmlHttpHelpers = xmlHttpHelpers;
        this.logger = LoggerFactory.getLogger(XMLHTTPUpload.name);
    }
    uploadFileUsingFileDir() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let nonPaySender = "";
                let fFrom = document.getElementById(constVar["sd_fFrom"]);
                if (fFrom) {
                    nonPaySender = fFrom.value;
                }
                let existingFiles = getExistingFiles();
                let sizeOfAllFiles = getSizeOfAllFiles();
                if (sizeOfAllFiles > constVar["sd_largeSizeThresholdDflt"]) {
                    let uploadPromises = [];
                    for (let elementID in existingFiles) {
                        let inputField = document.getElementById(elementID);
                        if (inputField) {
                            let tempFiles = new DataTransfer();
                            inputField.files = tempFiles.files;
                        }
                        let files = existingFiles[elementID];
                        if (!inputField) {
                            this.logger.error("inputField not found");
                            return false;
                        }
                        for (let i = 0; i < files.length; i++) {
                            uploadPromises.push(this.uploadFile(files[i], i, elementID, nonPaySender, inputField.name, elementID));
                        }
                    }
                    yield Promise.all(uploadPromises);
                }
                return true;
            }
            catch (error) {
                this.logger.error("Error uploading file: ", error);
            }
            return false;
        });
    }
    /*
    * Uploads a file to the backend via XMLHTTP Request
    *
    * @file - the file object to be uploaded to the backend
    * @fileIndex - the index of the file in the input field
    * @inputID - the id of the input field that contains the file
    * @nonPayingSenderLogin - mail of the uploader
    * @fieldName - name of the field that contains the file
    * @inputField - html dom ID of the input field that contains the file
    */
    uploadFile(file, fileIndex, inputID = "", nonPayingSenderLogin = "please@ignore.com", fieldName = "pleaseIgnore", inputField = "pleaseIgnore") {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    let xhr = new XMLHttpRequest();
                    let bar = document.createElement("div");
                    let progress = document.createElement("div");
                    let barDiv = this.xmlHttpHelpers.initializeProgressBar(inputID, fileIndex, progress, bar, file);
                    if (xhr.upload) {
                        xhr.upload.onprogress = (event) => {
                            this.XMLHandler.onProgressLarge(inputID, fileIndex, event, barDiv, bar, xhr, file);
                        };
                        xhr.upload.onload = (event) => {
                            this.XMLHandler.onloadLarge(event, barDiv, progress);
                        };
                        xhr.upload.onerror = xhr.upload.onabort = (event) => {
                            this.XMLHandler.onabortLarge(event, barDiv, progress, file, reject);
                        };
                    }
                    xhr.onload = (event) => {
                        return this.XMLHandler.onload(event, xhr, reject, file, inputID, resolve);
                    };
                    xhr.onerror = xhr.onabort = (event) => {
                        this.XMLHandler.onerror(event, inputID, file, reject, resolve);
                    };
                    this.logger.info(`addFile: about to request.send(${file.name}), size ${file.size}, mediaType "${file.type}"`);
                    let reqUrlWithParams = this.xmlHttpHelpers.getReqParams(file, fieldName, inputField, nonPayingSenderLogin);
                    xhr.open("POST", reqUrlWithParams, true);
                    this.XMLHandler.setRequestHeaderFromForm(xhr);
                    xhr.send(file);
                    return true;
                }
                catch (error) {
                    this.logger.error("Network error during upload: ", error);
                    reject(new Error("Network error during upload: " + file.name));
                }
                return false;
            });
        });
    }
}
