/**
 * @author gudiskis PrivaSphere confidential, (c) 2025 - 2025 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/165
 *
 */
import { LoggerFactory } from "myd-commons";
import { RequestUtils } from "../RequestUtils";
import { AUTO_UPLOAD_FILE, BAR, createDataProgress } from "../BrowserUpload/uiUpdates";
import constVar from "../../../angular/src/assets/const.json";
export class XmlHttpHelpers {
    constructor() {
        this.logger = LoggerFactory.getLogger(XmlHttpHelpers.name);
    }
    getReqParams(file, fieldName, inputField, nonPayingSenderLogin) {
        let encodedFilNam = this.encodeRFC5987ValueChars(file.name);
        let delim = "?";
        let reqUrl = "/" + constVar["gc_XML_ATTA5448"] + RequestUtils.completeReqURL("?");
        if (reqUrl.includes(delim)) {
            delim = "&";
        }
        reqUrl += delim;
        let reqUrlWithParams = reqUrl;
        if (encodedFilNam && encodedFilNam !== "") {
            reqUrlWithParams += constVar["gc_X_FILE_NAME"] + "=" + encodedFilNam + "&";
        }
        if (fieldName && fieldName !== "") {
            reqUrlWithParams += constVar["gc_X_FIELD_NAME"] + "=" + fieldName + "&";
        }
        if (inputField && inputField !== "") {
            reqUrlWithParams += constVar["gc_INPUT_FIELD_ID"] + "=" + inputField + "&";
        }
        if (nonPayingSenderLogin && nonPayingSenderLogin !== "") {
            reqUrlWithParams += constVar["gc_NON_PAY_SENDR"] + "=" + nonPayingSenderLogin + "&";
        }
        return reqUrlWithParams + this.updateUrlParam();
    }
    updateUrlParam() {
        if (typeof urlParameters === "undefined" || urlParameters === null || urlParameters === "") {
            this.logger.error("urlParameters is not defined");
            return "";
        }
        let updatedParams = "";
        let crt = RequestUtils.getParamFromString(constVar["gc_CLI_CRT_AUTH"].toLowerCase(), urlParameters.toLowerCase());
        if (crt !== "") {
            updatedParams += `${constVar["gc_CLI_CRT_AUTH"]}=` + crt + "&";
        }
        let c = RequestUtils.getParamFromString(constVar["jc_C_c"].toLowerCase(), urlParameters.toLowerCase());
        if (c !== "") {
            updatedParams += `${constVar["jc_C_c"]}=` + c + "&";
        }
        let to = RequestUtils.getParamFromString(constVar["gc_TO_SESS_KEY"].toLowerCase(), urlParameters.toLowerCase());
        if (to !== "") {
            updatedParams += `${constVar["gc_TO_SESS_KEY"]}=` + to + "&";
        }
        return updatedParams;
    }
    encodeRFC5987ValueChars(str) {
        return encodeURIComponent(str)
            .replace(/['()]/g, escape)
            .replace(/\*/g, "%2A")
            .replace(/%(?:7C|60|5E)/g, unescape);
    }
    getBarDIV(inputID, fileIndex) {
        return document.getElementById(`${BAR}${inputID}_` + fileIndex);
    }
    initializeProgressBar(inputID, fileIndex, progress, bar, file) {
        let barDiv = this.getBarDIV(inputID, fileIndex);
        if (barDiv) {
            this.createProgressBar(barDiv, progress, bar);
        }
        else {
            const fileDiv = document.getElementById(`${AUTO_UPLOAD_FILE}${inputID}_` + fileIndex);
            if (fileDiv) {
                createDataProgress(inputID, fileIndex, fileDiv);
                barDiv = this.getBarDIV(inputID, fileIndex);
                if (barDiv) {
                    this.createProgressBar(barDiv, progress, bar);
                }
                else {
                    this.logger.warn(`${inputID}_${fileIndex}: was not able to create the bar DIV , "${file.name}"`);
                }
            }
            else {
                this.logger.warn(`${inputID}_${fileIndex}: was not able to find the bar DIV , "${file.name}"
                    also "${AUTO_UPLOAD_FILE}${inputID}_${fileIndex}" does not exist`);
            }
        }
        return barDiv;
    }
    createProgressBar(barDiv, progress, bar) {
        barDiv.appendChild(progress);
        progress.className = "progress";
        bar.style.width = "1%";
        progress.appendChild(bar);
    }
}
