/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope-common/-/issues/613
 * https://wiki.privasphere.com/index.php/Datepicker
 *
 */
import { LoggerFactory } from "myd-commons";
import { DateValidator } from "./FieldValidators/DateValidator";
import { translationCurrent } from "./languages/LanguageFormat";
import constVar from "../../angular/src/assets/const.json";
export class DatePickerUtils extends DateValidator {
    constructor() {
        super();
        /** see also Constants.DATE_FMT_REGLR
        * yyyy-mm-dd format
        */
        this.iso8601Format = /^\d{4}-\d{2}-\d{2}$/;
        /**
         * datePatterns is used to check an input and determine what format it is
         * - The format must contain one of the three specific delimiter - / . and the order of the date parts.
         * - The date also must consist of mm dd and yyyy parts.
         * - It will not match if you have mm-dd-yy because it is not a valid date format.
         * - the month day year is currently ignored or rather defaulted to day month year
         */
        this.datePatterns = [
            // yyyy-mm-dd variations, delimiter is -
            // default date is dd-mm-yyyy for en
            { regex: /^(\d{4})-(\d{2})-(\d{2})$/, order: ["year", "month", "day"] },
            { regex: /^(\d{2})-(\d{2})-(\d{4})$/, order: ["day", "month", "year"] },
            { regex: /^(\d{2})-(\d{4})-(\d{2})$/, order: ["month", "year", "day"] },
            { regex: /^(\d{4})-(\d{2})-(\d{2})$/, order: ["year", "day", "month"] },
            // yyyy/mm/dd variations, delimiter is /
            // default date is dd/mm/yyyy for fr and it
            { regex: /^(\d{4})\/(\d{2})\/(\d{2})$/, order: ["year", "month", "day"] },
            { regex: /^(\d{2})\/(\d{2})\/(\d{4})$/, order: ["day", "month", "year"] },
            { regex: /^(\d{2})\/(\d{4})\/(\d{2})$/, order: ["month", "year", "day"] },
            { regex: /^(\d{4})\/(\d{2})\/(\d{2})$/, order: ["year", "day", "month"] },
            // yyyy.mm.dd variations
            // default date is dd.mmyyyy for de
            { regex: /^(\d{4})\.(\d{2})\.(\d{2})$/, order: ["year", "month", "day"] },
            { regex: /^(\d{2})\.(\d{2})\.(\d{4})$/, order: ["day", "month", "year"] },
            { regex: /^(\d{2})\.(\d{4})\.(\d{2})$/, order: ["month", "year", "day"] },
            { regex: /^(\d{4})\.(\d{2})\.(\d{2})$/, order: ["year", "day", "month"] }
        ];
        this.showMainError = this.showMainError.bind(this);
        this.logger = LoggerFactory.getLogger(DatePickerUtils.name);
        // en date format is mm/dd/yyyy and the default was dd/mm/yyyy
        if ('en' == currentLanguageTS) {
            this.datePatterns[5].order = ["month", "day", "year"];
        }
    }
    showError(errMsg) {
        this.showErrorInfo(errMsg, this.showMainError);
        setTimeout(() => {
            var _a, _b, _c;
            const infoMessage = (_b = (_a = this.inputField) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.querySelector("#" + this.errorContainerID + ((_c = this.inputField) === null || _c === void 0 ? void 0 : _c.id));
            if (infoMessage) {
                this.removeErrorMessage();
            }
        }, 10000);
    }
    showMainError() {
        const error = this.parser.getErrorByProperty("date.backend.incorrect");
        this.showMainErrorInfo(error === null || error === void 0 ? void 0 : error.err, (error === null || error === void 0 ? void 0 : error.property) + " " + (error === null || error === void 0 ? void 0 : error.constant), error === null || error === void 0 ? void 0 : error.errNo);
    }
    convertDateFormat(dateStr) {
        if (!dateStr) {
            this.logger.warn("empty date string");
            return "";
        }
        let outputFormat = standardDateFormat.toLowerCase();
        let { year, month, day } = this.parseDateToParts(dateStr);
        if (!year || !month || !day) {
            this.logger.error(`Invalid date format dateStr: ${dateStr}, year: ${year}, month: ${month}, day: ${day}`);
            throw new Error(`Invalid date format dateStr: ${dateStr}, year: ${year}, month: ${month}, day: ${day}`);
        }
        const formattedDate = outputFormat
            .replace("yyyy", year.toLowerCase())
            .replace("mm", month.toLowerCase())
            .replace("dd", day.toLowerCase());
        this.logger.info(`Converted date string ${dateStr} to ISO8601 format: ${formattedDate}`);
        return formattedDate;
    }
    parseDateToParts(dateStr) {
        let year = "";
        let month = "";
        let day = "";
        for (const pattern of this.datePatterns) {
            const match = dateStr.match(pattern.regex);
            if (match) {
                pattern.order.forEach((part, index) => {
                    if (part === "year")
                        year = match[index + 1];
                    if (part === "month")
                        month = match[index + 1];
                    if (part === "day")
                        day = match[index + 1];
                });
                break;
            }
        }
        return { year, month, day };
    }
    convertISO8601ToString(dateStr) {
        if (!dateStr) {
            this.logger.warn("empty date string");
            return "";
        }
        let { year, month, day } = this.parseDateToParts(dateStr);
        if (!year || !month || !day) {
            this.logger.error(`Invalid date format dateStr: ${dateStr}, year: ${year}, month: ${month}, day: ${day}`);
            throw new Error(`Invalid date format dateStr: ${dateStr}, year: ${year}, month: ${month}, day: ${day}`);
        }
        let formattedDate;
        switch (currentLanguageTS) {
            case 'fr':
            case 'it':
                formattedDate = `${day}/${month}/${year}`;
                break;
            case 'de':
                formattedDate = `${day}.${month}.${year}`;
                break;
            case 'en':
                formattedDate = `${month}/${day}/${year}`;
                break;
            default:
                this.logger.error(`Unsupported language: ${currentLanguageTS}`);
                throw new Error(`Unsupported language: ${currentLanguageTS}`);
        }
        this.logger.info(`Converted date string ${dateStr} from ISO8601 format to ${currentLanguageTS} format: ${formattedDate}`);
        return formattedDate;
    }
    //used for ALL input field within the form
    //this function is used when backend requires specific date format, this will convert from language specific format to the provided format
    preprocessDateInputsISO8601(format) {
        standardDateFormat = format;
    }
    processDateBeforeSubmit() {
        let contaFrmSnd = document.getElementById(constVar["sd_contaFrmSndCustom"]);
        if (!contaFrmSnd) {
            contaFrmSnd = document.getElementById(constVar["sd_contaFrmSnd"]);
        }
        if (contaFrmSnd) {
            contaFrmSnd.addEventListener("click", (event) => {
                this.convertDateFields();
            });
        }
    }
    convertDateFields() {
        let inputs = document.querySelectorAll('div[id^="sandbox-container-"] input');
        inputs.forEach((input) => {
            const inputField = input;
            const dateStr = inputField.value;
            if (dateStr) {
                inputField.value = this.convertDateFormat(dateStr);
            }
        });
    }
    //used for specific input fields
    //this function is used when backend requires specific date format, this will convert from language specific format to the provided format
    // public preprocessDateInputISO8601(inputField: HTMLInputElement, format: string): void {
    // 	const dateStr = inputField.value;
    //     if (dateStr) {
    //         inputField.value = this.convertDateFormat(dateStr, format);
    //     }
    // }
    processParamDate(inputID, param) {
        const inputField = document.getElementById(inputID);
        this.inputField = inputField;
        this.parentElementDom = inputField.parentElement;
        let dateStr = inputField[param];
        if (!dateStr)
            return undefined;
        if ("" === dateStr)
            return undefined;
        const notifMsg = translationCurrent.ts_unsupportedDateFormat +
            ` "${dateStr}", ${translationCurrent.ts_parameter} "${param}"`;
        if (!dateStr.includes('-') && !this.iso8601Format.test(dateStr)) {
            let { year, month, day } = this.parseDateToParts(dateStr);
            if (!year || !month || !day) {
                this.logger.error(`Unsupported date format "${dateStr}" for input field ID "${inputID}" parameter ${param}`);
                this.showError(notifMsg);
                return undefined;
            }
            dateStr = `${year}-${month}-${day}`;
        }
        if (dateStr) {
            try {
                let date = new Date(dateStr);
                if ("value" === param) {
                    inputField[param] = this.convertISO8601ToString(dateStr);
                }
                return date;
            }
            catch (e) {
                this.logger.error(`Failed to parse min date "${dateStr}" for input field ID "${inputID}" parameter ${param}`, e);
                this.showError(notifMsg);
                return undefined;
            }
        }
        return undefined;
    }
}
const datePickerUtils = new DatePickerUtils();
export function preprocessDateInputsISO8601(format) {
    datePickerUtils.preprocessDateInputsISO8601(format);
}
// export function preprocessDateInputISO8601(inputField: HTMLInputElement, format: string): void {
//   datePickerUtils.preprocessDateInputISO8601(inputField, format);
// }
export function processParamDate(inputID, param) {
    return datePickerUtils.processParamDate(inputID, param);
}
export function processDateBeforeSubmit() {
    datePickerUtils.processDateBeforeSubmit();
}
