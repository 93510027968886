/**
 * @author gudiskis PrivaSphere confidential, (c) 2025 - 2025 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/165
 *
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { XMLHTTPUpload } from './FileUploader';
import { XMLHandlerImplementation } from './XMLHTTPImplementation';
import { XMLHandlerImplementationAngular } from './XMLHandlerImplementationAngular';
import { XmlHttpHelpers } from './XmlHttpHelpers';
export function uploadFileInline() {
    return __awaiter(this, void 0, void 0, function* () {
        const xhrHandler = new XMLHandlerImplementation();
        const xmlHttpHelpers = new XmlHttpHelpers();
        const xmlUpload = new XMLHTTPUpload(xhrHandler, xmlHttpHelpers);
        return yield xmlUpload.uploadFileUsingFileDir();
    });
}
export function uploadFile(file, fileIndex, inputID = "fFile", nonPayingSenderLogin = "please@ignore.com", fieldName = "pleaseIgnore", inputField = "pleaseIgnore") {
    return __awaiter(this, void 0, void 0, function* () {
        const xmlImpl = new XMLHandlerImplementationAngular();
        const xmlHttpHelpers = new XmlHttpHelpers();
        const xmlUpload = new XMLHTTPUpload(xmlImpl, xmlHttpHelpers);
        return yield xmlUpload.uploadFile(file, fileIndex, inputID, nonPayingSenderLogin, fieldName, inputField);
    });
}
