import { LoggerFactory } from "myd-commons";
import { XMLHandlerImplementation } from "./XMLHTTPImplementation";
export class XMLHandlerImplementationAngular {
    constructor() {
        this.logger = LoggerFactory.getLogger(XMLHandlerImplementationAngular.name);
    }
    onload(event, xhr, reject, file, inputID, resolve) {
        this.logger.log(`onload ${xhr.status} "${file.name}" ${xhr.getAllResponseHeaders()}`);
        if (xhr.status !== 200 && xhr.status !== 204) {
            this.logger.warn(`status is not 200 or 204 ${xhr.status} "${file.name}"`);
            reject(new Error(xhr.response));
            return xhr.onerror(event);
        }
        resolve(true);
        return true;
    }
    onerror(event, inputID, file, reject) {
        this.logger.error(`onerror: ${file.name}, event ${event}, type ${event.type}`);
        return false;
    }
    onreadystatechange(xhr, inputID, resolve, reject) {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                this.logger.info('successful');
                resolve();
            }
            else {
                this.logger.info('failed: xhr.status ' + xhr.status);
            }
        }
        else {
            this.logger.trace('error request state: ' + xhr.readyState);
        }
        return true;
    }
    onabortLarge(event, barDiv, progress, file, reject) {
        this.logger.log(`onabort ${file.name}, event ${event}, type ${event.type}`);
        if (null != barDiv) {
            barDiv.removeChild(progress);
        }
    }
    onloadLarge(event, barDiv, progress) {
        if (null != barDiv) {
            barDiv.removeChild(progress);
        }
    }
    onProgressLarge(inputID, fileIndex, event, barDiv, bar, xhr, file) {
        const imp = new XMLHandlerImplementation();
        imp.onProgressLarge(inputID, fileIndex, event, barDiv, bar, xhr, file);
    }
    setRequestHeaderFromForm(xhr) {
        return xhr;
    }
}
