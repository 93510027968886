/**
 * @author chang, hauser PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/133
 *
 */
import { LoggerFactory } from "myd-commons";
import { Validator } from "./Validator";
import { Utils } from "../Utils";
import { translationCurrent } from "../languages/LanguageFormat";
//this class implements https://wiki.privasphere.com/index.php/TF02_Formular_erstellen
export class EmailValidator extends Validator {
    constructor() {
        super();
        this.customErrMsg = "";
        this.showMainError = this.showMainError.bind(this);
        this.showMainNotif = this.showMainNotif.bind(this);
        this.logger = LoggerFactory.getLogger(EmailValidator.name);
    }
    // Method to validate user input when used on onChange
    validateInputOnChange(inputField, divID = null, multiple = false, dbg = "", customErrMsg = "") {
        this.customErrMsg = customErrMsg;
        return this.validateInputOnChangeWiDbg(inputField, divID, multiple, dbg);
    }
    //true - the email is valid, false - the email is invalid
    validateInputOnChangeWiDbg(pInputField, divID, multiple, dbg) {
        this.logger.log(dbg + ": in 'validateInputOnChangeWiDbg'");
        this.initializeValidator(pInputField, divID, dbg);
        if ('' === pInputField.value || '' === pInputField.value.trim()) {
            return true;
        }
        let fromStr = this.inputField.value;
        const errStart = 'The input is "' + fromStr + '". ' + dbg;
        this.logger.info(errStart);
        this.cleanUpInput(this.inputField, multiple);
        fromStr = this.inputField.value;
        let errMsg = "";
        if (multiple) {
            if (-1 != fromStr.indexOf(';')) {
                errMsg = translationCurrent.ts_wrongEmailSeparators;
                this.showErrorAndLogger(errMsg, errStart);
            }
        }
        else {
            if (-1 != fromStr.indexOf(',')) {
                errMsg = translationCurrent.ts_emailmustonlyone;
                this.showErrorAndLogger(errMsg, errStart, " !multiple");
            }
        }
        let splitByComma = fromStr.split(",");
        for (let email of splitByComma) {
            if (-1 == email.indexOf('@')) {
                errMsg = translationCurrent.ts_emailmusthaveat;
                this.showErrorAndLogger(errMsg, errStart);
            }
            let emailTrim = email.trim();
            if (null == emailTrim.match(/\S+\w\.\w{2,}$/)) {
                errMsg = translationCurrent.ts_emailmusthavedomain;
                this.showErrorAndLogger(errMsg, errStart);
            }
        }
        return this.showAllEventTrackerMessages();
    }
    cleanUpInput(inputField, multiple = false) {
        //used for stepping through the code
        const original0 = inputField.value;
        const prevLgth0 = original0.length;
        this.notifMessage(inputField, /\s/g, "", "ts_WhitespaceRemoved");
        //used for stepping through the code
        const original1 = inputField.value;
        const prevLgth1 = original1.length;
        this.notifMessage(inputField, /[.]+/g, ".", "ts_MultipleDecimalDelimitersReducedToOne");
        //used for stepping through the code
        const original2 = inputField.value;
        const prevLgth2 = original2.length;
        this.notifMessage(inputField, /^[^a-zA-Z0-9.@]+|[^a-zA-Z0-9.@]+$/g, "", "ts_leadtrailRemoved");
        if (multiple) {
            const original = inputField.value;
            const prevLgth = original.length;
            const res = Utils.replaceAndCount(original, ';', ',');
            let changed = "";
            if (null != res && 0 < res.count) {
                changed = res.modifiedString;
                this.notifMessageChanged(changed, original, prevLgth + res.count, "ts_wrongEmailSeparators" //"mistaken separator"
                , inputField, "replaced");
            }
        }
        this.notifMessage(inputField, /\.@\.|@\.|\.@/g, "@", "ts_extraDotRemovedfromAdjacenttoAt");
    }
    showMainError() {
        var _a, _b;
        const error = this.parser.getErrorByPropertyPassValues("errors.email", [(_a = this.inputField) === null || _a === void 0 ? void 0 : _a.value]);
        let errMsg = error === null || error === void 0 ? void 0 : error.err;
        if (this.customErrMsg) {
            this.eventTracker.error.push({
                message: errMsg,
                log: "the default error message has been overridden"
            });
            errMsg = `"${(_b = this.inputField) === null || _b === void 0 ? void 0 : _b.value}" ${this.customErrMsg}`;
        }
        this.showMainErrorInfo(errMsg, (error === null || error === void 0 ? void 0 : error.property) + " " + (error === null || error === void 0 ? void 0 : error.constant), error === null || error === void 0 ? void 0 : error.errNo);
    }
    showErrorAndLogger(errMsg, errStart, endLog = "") {
        let logMsg = errStart + " " + errMsg + endLog;
        this.showError(errMsg);
        this.logger.warn(logMsg);
        this.eventTracker.error.push({
            message: errMsg,
            log: logMsg
        });
    }
    showError(errMsg) {
        this.showErrorInfo(errMsg, this.showMainError);
    }
    showMainNotif() {
        const notif = this.parser.getNotificationByProperty("email.formatted");
        this.showMainNotifInfo(notif.err);
    }
    showNotif(infoMsg) {
        this.showNotifInfo(infoMsg, this.showMainNotif);
        // Hide notif after 10 seconds
        setTimeout(() => {
            var _a, _b, _c;
            const infoMessage = (_b = (_a = this.inputField) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.querySelector("#" + this.notifContainerID + ((_c = this.inputField) === null || _c === void 0 ? void 0 : _c.id));
            if (infoMessage) {
                this.removeNotifMessage();
            }
        }, 10000);
    }
}
export function validateEmailInput(inputField, divID, multiple = false, what = "") {
    const emailValidator = new EmailValidator();
    emailValidator.validateInputOnChange(inputField, divID, multiple, what);
}
export function validateEmailInputPassCustomErrMsg(inputField, customErrMsg) {
    const emailValidator = new EmailValidator();
    emailValidator.validateInputOnChange(inputField, "", false, "", customErrMsg);
}
