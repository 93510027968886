/**
 * @author gudiskis, hauser PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 */
import { translations } from "../languages/LanguageFormat";
import { XMLParser } from "../XMLParser";
import { Utils } from "../Utils";
import { LoggerFactory } from "myd-commons";
import constVar from "../../../angular/src/assets/const.json";
import { translationCurrent } from "../languages/LanguageFormat";
export class Validator {
    showNotif(infoMsg) {
        this.showNotifInfo(infoMsg, this.showMainNotif);
        setTimeout(() => {
            var _a, _b, _c;
            let infoMessage = (_b = (_a = this.inputField) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.querySelector("#" + this.notifContainerID + ((_c = this.inputField) === null || _c === void 0 ? void 0 : _c.id));
            if (!infoMessage && this.parentElementDom) {
                infoMessage = this.parentElementDom;
            }
            if (infoMessage) {
                this.removeNotifMessage();
            }
        }, 10000);
    }
    constructor() {
        this.parser = new XMLParser();
        this.paddingSize = 1;
        this.errorContainerID = "error-container";
        this.errorMessageID = "error-message";
        this.errorMessageDetailsID = "error-details";
        this.notifContainerID = "notif-container";
        this.notifMessageID = "notif-message";
        this.notifMessageDetailsID = "notif-details";
        this.translationEN = translations.en;
        this.eventTracker = {
            notification: [],
            error: []
        };
        this.CSSclass = "";
        this.logger = LoggerFactory.getLogger(Validator.name);
    }
    cleanUpEventTracker() {
        this.eventTracker.error = [];
        this.eventTracker.notification = [];
    }
    createBrElement(element) {
        const brEl = document.createElement("br");
        element.appendChild(brEl);
    }
    setElementStyle() {
        let notif = document.createElement("div");
        notif.style.fontWeight = "bold";
        notif.style.fontSize = "15";
        notif.style.marginBottom = "10";
        notif.style.marginTop = "3";
        return notif;
    }
    addPaddingToParent(parent, id) {
        let count = this.getNumOfInfoBoxes(parent, id);
        parent.style.paddingBottom = count + "px";
    }
    numberOfMessages(parent, id, type) {
        let notifMessages = parent.querySelectorAll("#" + type + id);
        return notifMessages.length * this.paddingSize;
    }
    createPlusIcon(infoDetails, typeMessage) {
        const plusIcon = document.createElement("span");
        plusIcon.textContent = "+";
        plusIcon.style.cursor = "pointer";
        plusIcon.style.marginLeft = "5px";
        plusIcon.style.color = "black";
        plusIcon.style.fontSize = "20px";
        plusIcon.style.fontWeight = "bold";
        plusIcon.onclick = () => {
            if (infoDetails.style.display == "block") {
                plusIcon.textContent = "+";
                infoDetails.style.display = "none";
                let paddingSize = parseInt(this.parentElementDom.style.paddingBottom.replace('px', '')) -
                    this.numberOfMessages(infoDetails, this.inputField.id, typeMessage);
                if (paddingSize < this.paddingSize) {
                    paddingSize = this.paddingSize;
                }
                this.parentElementDom.style.paddingBottom = paddingSize + "px";
            }
            else {
                plusIcon.textContent = "-";
                infoDetails.style.display = "block";
                this.parentElementDom.style.paddingBottom =
                    parseInt(this.parentElementDom.style.paddingBottom.replace('px', '')) +
                        this.numberOfMessages(infoDetails, this.inputField.id, typeMessage) +
                        "px";
            }
        };
        return plusIcon;
    }
    showMainErrorInfo(infoMsg, errTitle, errCode) {
        this.showMainInfo(this.errorContainerID, "fieldError", constVar["ERROR_BOX_RED"], this.errorMessageID, infoMsg, this.errorMessageDetailsID, errTitle, errCode);
    }
    showErrorInfo(err, showMainInfo) {
        this.showInfo(constVar["ERROR_BOX_RED"], this.errorMessageID, err, this.errorMessageDetailsID, showMainInfo);
    }
    showNotifInfo(infoMsg, showMainInfo) {
        this.showInfo(constVar["ERROR_BOX_GREEN"], this.notifMessageID, infoMsg, this.notifMessageDetailsID, showMainInfo);
    }
    showMainNotifInfo(infoMsg) {
        this.showMainInfo(this.notifContainerID, null, constVar["ERROR_BOX_GREEN"], this.notifMessageID, infoMsg, this.notifMessageDetailsID);
    }
    showMainInfo(infoContainerID, cssClass, colorOfText, infoMsgID, infoMsg, infoDetailsID, errTitle, errCode) {
        if (this.inputField) {
            const infoContainer = document.createElement("div");
            infoContainer.id = infoContainerID + this.inputField.id;
            if (this.CSSclass) {
                const classes = this.CSSclass.split(' ').filter(Boolean);
                infoContainer.classList.add(...classes);
            }
            infoContainer.style.display = "table";
            infoContainer.style.paddingTop = "0.5em";
            if (cssClass) {
                this.inputField.classList.add(cssClass);
            }
            const infoMessage = this.setElementStyle();
            infoMessage.style.color = colorOfText;
            infoMessage.style.whiteSpace = "normal";
            infoMessage.id = infoMsgID + this.inputField.id;
            infoMessage.textContent = infoMsg;
            if (errTitle && errCode) {
                const acronym = document.createElement("acronym");
                acronym.title = errTitle;
                acronym.textContent = errCode;
                const small = document.createElement("small");
                small.innerHTML = " (";
                small.appendChild(acronym);
                small.innerHTML += ")";
                infoMessage.appendChild(small);
            }
            this.createBrElement(this.parentElementDom);
            infoContainer.appendChild(infoMessage);
            const infoDetails = document.createElement("div");
            infoDetails.style.display = "none";
            infoDetails.id = infoDetailsID + this.inputField.id;
            infoContainer.appendChild(infoMessage);
            infoMessage.appendChild(this.createPlusIcon(infoDetails, infoMsgID));
            infoContainer.appendChild(infoDetails);
            this.parentElementDom.appendChild(infoContainer);
            this.addPaddingToParent(this.parentElementDom, this.inputField.id);
            this.parentElementDom.style.display = "block";
        }
    }
    showInfo(color, messageID, info, infoMessageDetailsID, showMainInfo) {
        var _a, _b;
        if (this.inputField) {
            let infoContainer = (_a = this.parentElementDom) === null || _a === void 0 ? void 0 : _a.querySelector("#" + infoMessageDetailsID + this.inputField.id);
            if (!infoContainer) {
                showMainInfo();
                infoContainer = (_b = this.parentElementDom) === null || _b === void 0 ? void 0 : _b.querySelector("#" + infoMessageDetailsID + this.inputField.id);
            }
            const infoMessage = this.setElementStyle();
            infoMessage.style.color = color;
            infoMessage.style.whiteSpace = 'pre-wrap';
            infoMessage.id = messageID + this.inputField.id;
            infoMessage.textContent = info;
            infoContainer === null || infoContainer === void 0 ? void 0 : infoContainer.appendChild(infoMessage);
            this.createBrElement(infoContainer);
        }
    }
    numberOfBrElements(parent) {
        let brElements = parent.querySelectorAll("br");
        return brElements.length;
    }
    shortenString(str) {
        if (str.length > 6) {
            return str.substring(0, 6) + '...';
        }
        else {
            return str;
        }
    }
    removeInfoMessage(cssClass, infoContainerID, dbg = "") {
        if (this.inputField) {
            if (cssClass) {
                this.inputField.classList.remove(cssClass);
                this.removeClassIfEmpty();
            }
            const parentElement = this.parentElementDom;
            const infoMessage = this.parentElementDom.querySelector("#" + infoContainerID + this.inputField.id);
            if (infoMessage) {
                parentElement.removeChild(infoMessage);
                this.logger.log("removeInfoMessage: parentElement.removeChild(infoMessage) '" +
                    infoContainerID + "', cssClass'" + cssClass + "' " + dbg);
            }
            let brElement = parentElement.querySelector("br");
            if (brElement) {
                parentElement.removeChild(brElement);
            }
            this.parentElementDom.style.paddingBottom = (this.getNumOfInfoBoxes(this.parentElementDom, this.inputField.id) +
                this.getNumberOfChildren() * this.paddingSize) + "px";
        }
        else {
            this.logger.log("removeInfoMessage: no inputField '" + infoContainerID
                + "', cssClass '" + cssClass + "' " + dbg + " '");
        }
    }
    removeErrorAndNotif(dbg = "") {
        try {
            this.removeErrorMessage(dbg);
            this.removeNotifMessage(dbg);
            this.cleanUpEventTracker();
        }
        catch (e) {
            this.logger.trace(dbg + ": problem with 'remove*Message' " + e);
        }
    }
    removeErrorMessage(dbg = "") {
        this.removeInfoMessage("fieldError", this.errorContainerID, dbg);
    }
    removeClassIfEmpty() {
        if (this.inputField.classList.length === 0) {
            this.inputField.removeAttribute("class");
        }
    }
    getNumberOfChildren() {
        let parent = this.parentElementDom;
        let count = 0;
        if (parent) {
            let errEl = parent.querySelector("#" + this.errorMessageDetailsID + this.inputField.id);
            if (errEl && errEl.style.display === "block") {
                count += parent.querySelectorAll("#" + this.errorMessageDetailsID + this.inputField.id).length;
            }
            let notifEl = parent.querySelector("#" + this.notifMessageDetailsID + this.inputField.id);
            if (notifEl && notifEl.style.display === "block") {
                count += parent.querySelectorAll("#" + this.notifMessageDetailsID + this.inputField.id).length;
            }
        }
        return count;
    }
    getNumOfInfoBoxes(parent, id) {
        let notifMessages = parent.querySelectorAll("#" + this.notifContainerID + id + ", #" + this.errorContainerID + id);
        let count = notifMessages.length;
        return count * this.paddingSize + this.numberOfBrElements(parent) * 5;
    }
    removeNotifMessage(dbg = "") {
        this.removeInfoMessage(null, this.notifContainerID, dbg);
    }
    notifMessage(inputField, pattern, replace, notifMsg) {
        const original = inputField.value;
        const prevLgth = original.length;
        let changed = original.replace(pattern, replace);
        this.notifMessageChanged(changed, original, prevLgth, notifMsg, inputField, "removed");
    }
    trimInput(inputField) {
        const original = inputField.value;
        const prevLgth = original.length;
        let changed = inputField.value.trim();
        this.notifMessageChanged(changed, original, prevLgth, "ts_whiteSpacesAtStartEndRemoved", inputField, "removed");
    }
    removeNonMatching(inputField, notifMsg, what) {
        const pat = inputField.pattern;
        if (null == pat) {
            this.logger.warn("Pattern empty, cannot removeNonMatching");
            return;
        }
        if (!pat.startsWith("[")) {
            this.logger.warn("Pattern not just describing a set of " +
                "permitted characters, thus cannot removeNonMatching");
            return;
        }
        const pattern = pat.replace("[", "[^");
        const regex = new RegExp(pattern, "g");
        this.removeWhat(inputField, regex, notifMsg, what);
        //TODO one could also cite all the characters that are dropped (ChatGPT has a simple proposal how to do this)
    }
    notifMessageRepl(inputField, pattern, replace, notifMsg) {
        const original = inputField.value;
        const prevLgth = original.length;
        let changed = original.replace(pattern, replace);
        this.notifMessageChangedPlusPlus(changed, original, prevLgth, notifMsg, inputField, "removed", " '" + replace + "'", " '" + replace + "'");
    }
    removeWhat(inputField, pattern, notifMsg, what) {
        const original = inputField.value;
        const prevLgth = original.length;
        let changed = original.replace(pattern, "");
        this.notifMessageChangedPlusPlus(changed, original, prevLgth, notifMsg, inputField, "removed", " '" + what + "'", " '" + what + "'");
    }
    notifMessageChangedPlusPlus(changed, original, prevLgth, notifMsg, inputField, action, notifMsgExtra, logMsgExtra) {
        if (changed !== original) {
            const newLgth = changed.length;
            const removed = prevLgth - newLgth;
            inputField.value = changed;
            const logMsg = 'Input "' + original + '" has been changed to "' +
                changed + '". ' + removed +
                " chars have been " + action + ". " + this.translationEN[notifMsg] + logMsgExtra;
            const toShowNotifMsg = translationCurrent[notifMsg] + notifMsgExtra + " "
                + translationCurrent.sd_From + " \"" + original + "\" " +
                translationCurrent.sd_to + " \"" + changed + "\"";
            this.trackNotif(toShowNotifMsg, logMsg);
        }
    }
    /*
     * we don't know yet whether this will be a notification or end up in an error
     * (and be logged, but not shown to user)
     */
    trackNotif(pMessage, pLogMsg) {
        this.eventTracker.notification.push({
            message: pMessage,
            log: pLogMsg
        });
    }
    trackNotifTransl(notifMsgK, extraMsg) {
        const logMsg = this.translationEN[notifMsgK] + extraMsg;
        const toShowNotifMsg = translationCurrent[notifMsgK] + extraMsg;
        this.trackNotif(toShowNotifMsg, logMsg);
    }
    notifMessageChanged(changed, original, prevLgth, notifMsg, inputField, action) {
        this.notifMessageChangedPlusPlus(changed, original, prevLgth, notifMsg, inputField, action, "", "");
    }
    showAllEventTrackerMessages() {
        // for (let i = 0; i < this.eventTracker.error.length; i++) {
        //   this.logger.warn(this.eventTracker.error[i].log);
        //   this.showMainErrorInfo(this.eventTracker.error[i].message);
        // }
        let hasErrorOccurred = 0 !== this.eventTracker.error.length;
        for (let i = 0; i < this.eventTracker.notification.length; i++) {
            if (!hasErrorOccurred) {
                this.showNotif(this.eventTracker.notification[i].message);
            }
            this.logger.info(this.eventTracker.notification[i].log);
        }
        return !hasErrorOccurred;
    }
    getVisibleInputsCount(parent) {
        const inputs = parent.querySelectorAll('input');
        const visibleInputs = Array.from(inputs).filter((input) => {
            var _a;
            const inputType = (_a = input.getAttribute('type')) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            const isNotHiddenType = inputType !== 'hidden';
            const isVisibleStyle = input.offsetParent !== null
                && window.getComputedStyle(input).display !== 'none'
                && window.getComputedStyle(input).visibility !== 'hidden'
                && input.offsetWidth > 0
                && input.offsetHeight > 0;
            return isNotHiddenType && isVisibleStyle;
        });
        return visibleInputs.length;
    }
    getParentEl(pInputField) {
        if (!pInputField || !pInputField.parentElement) {
            throw new Error(Utils.getCurrentTimeString() + " Error: The element or the parent element is null");
        }
        const parrentEl = document.getElementById(pInputField.id + "r");
        if (parrentEl) {
            if (2 <= this.getVisibleInputsCount(parrentEl)) {
                this.CSSclass += " inpCell";
                return parrentEl;
            }
        }
        else {
            try {
                let parentID = pInputField.id;
                parentID = parentID.replace(/\D/g, '');
                if (parentID && Utils.isNumeric(parentID)) {
                    const pID = Number(parentID) - 1;
                    const parentEl = document.getElementById("f" + pID + "r");
                    if (parentEl) {
                        this.CSSclass += " inpCell";
                        return parentEl;
                    }
                }
            }
            catch (error) {
                this.logger.error("Failed to get root parent", error);
            }
        }
        return pInputField.parentElement;
    }
    initializeValidator(pInputField, divID = null, dbg = "") {
        if (this.inputField && this.parentElementDom) {
            this.logger.info("skipping initialization as inputField and parentEl is set");
            return;
        }
        if (!pInputField || !pInputField.parentElement) {
            throw new Error(Utils.getCurrentTimeString() +
                " Error: The element or the parent element is null");
        }
        this.inputField = pInputField;
        this.parentElementDom = this.getParentEl(pInputField);
        if (divID) {
            let notParentDiv = document.getElementById(divID);
            if (notParentDiv) {
                this.parentElementDom = notParentDiv;
                this.CSSclass.replace(" inpCell", "");
            }
            else {
                this.logger.trace(dbg + ": initializeValidator 'divID' = '" + divID +
                    "' does not exist ");
            }
        }
        this.removeErrorAndNotif(dbg);
    }
    removeDoubleSpaces(inputField) {
        const original = inputField.value;
        let intermidiate = inputField.value;
        let changed = inputField.value.replace(/\s{2}/gm, " ");
        while (intermidiate !== changed) {
            intermidiate = changed;
            changed = changed.replace(/\s{2}/gm, " ");
        }
        this.notifMessageChanged(changed, original, original.length, "ts_whitespacesRemoved", inputField, "removed");
    }
}
